:global(.tippy-content) {
  white-space: pre-wrap;
}

:global(.tippy-box) {
  color: inherit;
}
:global(.tippy-content) {
  white-space: pre-wrap;
}

:global(.tippy-box) {
  color: inherit;
}
